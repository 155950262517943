<template>
  <div id="crypto-dashboard" class="container">
    <h1>Crypto Dashboard</h1>
    <div>
      <label for="timeframe">Choose a timeframe:</label>
      <select
        id="timeframe"
        v-model="selectedTimeframe"
        @change="updateTimeframe"
      >
        <option v-for="frame in timeframes" :key="frame" :value="frame">
          {{ frame }}
        </option>
      </select>
    </div>
    <div id="crypto-data" class="crypto-container">
      <div
        v-for="crypto in cryptoData"
        :key="crypto.symbol"
        class="crypto-card"
      >
        <h2>{{ crypto.symbol }}</h2>
        <p>
          💰 Current Price:
          <span :id="`${crypto.symbol.replace('/', '_')}_price`">{{ formatNumber(crypto.price_now) }}</span>
        </p>
        <p>📉 Future Low: {{ formatNumber(crypto.future_low) }}</p>
        <p>📈 Future High: {{ formatNumber(crypto.future_high) }}</p>
        <p>📊 Up Probability: {{ crypto.up_prob }}%</p>
        <p>📉 Down Probability: {{ crypto.down_prob }}%</p>
        <p>🔮 Short Term Signal: {{ crypto.short_term_signal }}</p>
        <p>🔮 Mid Term Signal: {{ crypto.mid_term_signal }}</p>
        <p>🔮 Long Term Signal: {{ crypto.long_term_signal }}</p>
        <div class="candle-time">
          <p>For candle between:</p>
          <p class="time">📅 {{ crypto.candle_time_start }}</p>
          <p class="line">|</p>
          <p class="time">📅 {{ crypto.candle_time_end }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cryptoData: [],
      selectedTimeframe: "15m",
      timeframes: [
        "1m",
        "3m",
        "5m",
        "15m",
        "30m",
        "1h",
        "2h",
        "4h",
        "1d",
        "1w",
        "1M",
      ],
    };
  },
  methods: {
    fetchPredictData() {
      fetch(
        `https://backend.cryptopy.pro/predicts?timeframe=${this.selectedTimeframe}`
      )
        .then((response) => response.json())
        .then((data) => {
          this.cryptoData = data;
          console.log("Predict data received:", data);
        })
        .catch((error) => console.error("Error fetching predict data:", error));
    },
    fetchPriceData() {
      fetch("https://backend.cryptopy.pro/crypto_prices")
        .then((response) => response.json())
        .then((data) => {
          this.cryptoData.forEach((crypto) => {
            crypto.price_now = data[crypto.symbol];
          });
        })
        .catch((error) => console.error("Error fetching price data:", error));
    },
    updateTimeframe() {
      this.fetchPredictData();
    },
    formatNumber(number) {
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 8 }).format(number);
    }
  },
  mounted() {
    this.fetchPredictData();
    setInterval(this.fetchPriceData, 2000); // Update prices every 2 seconds
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
  text-align: center;
}

h1 {
  margin-bottom: 20px;
}

.crypto-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
}

.crypto-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s;
  text-align: left; 
}

.crypto-card:hover {
  transform: translateY(-10px);
}

.crypto-card h2 {
  margin: 0 0 10px;
}

.crypto-card p {
  margin: 5px 0;
}

.candle-time {
  margin-top: 10px;
  text-align: center;
}

.candle-time .time {
  margin: 2px 0;
}

.candle-time .line {
  font-size: 24px;
}

@media (max-width: 600px) {
  .crypto-card {
    width: 100%;
  }
}
</style>