<template>
  <div id="app">
    <CryptoDashboard />
  </div>
</template>

<script>
import CryptoDashboard from './components/CryptoDashboard.vue';

export default {
  name: 'App',
  components: {
    CryptoDashboard
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font/css/materialdesignicons.min.css');

html, body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#app {
  text-align: center;
}
</style>
